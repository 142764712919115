<template>
  <div>
    <div class="d-flex mb-3 justify-content-between align-items-center">
      <h4 class="mb-0 font-17">
        Zumper Network
      </h4>
      <SdSwitchSaved
        :is-saved="uiFlags.showSaved"
        @hide-text="uiFlags.showSaved = false"
      >
        <ElSwitch
          v-model="isZumperSyndicationEnabled"
          class="col-auto d-block"
          :disabled="disabled || uiFlags.isLoading"
          :width="44"
          @change="updateSyndicationSettings"
        />
      </SdSwitchSaved>
    </div>
    <p class="font-15">
      Publish your property listings to Zumper and Padmapper when you start showings. Your property will also,
      typically, be added to Realtor, Movoto, Trovit, and Uloop, who monitor listings on Zumper.
    </p>
  </div>
</template>

<script>

import { reactive, ref } from '@vue/composition-api';
import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';
import SdSwitchSaved from '@/components/common/form/SdSwitchSaved.vue';

export default {
  name: 'ZumperSettingsBlock',
  components: { SdSwitchSaved },
  props: {
    business: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const uiFlags = reactive({ showDialog: false, showSaved: false, isLoading: false });
    const isZumperSyndicationEnabled = ref(props.business.zumper_syndication_enabled_at !== null);

    async function updateSyndicationSettings() {
      const action = isZumperSyndicationEnabled.value ? 'Enabled' : 'Disabled';

      try {
        uiFlags.isLoading = true;

        await context.root.$store
          .dispatch('BusinessSettings/updateAndCommitPayload', {
            meta: 'syndication.zumper',
            payload: {
              zumper: {
                is_zumper_syndication_enabled: isZumperSyndicationEnabled.value,
              },
            },
          });

        showSuccessNotify(
          context,
          `Zumper syndication has been <span class="font-weight-strong underline">${action}</span>.`,
          { dangerouslyUseHTMLString: true },
        );

        uiFlags.showSaved = true;
      } catch (error) {
        showErrorNotify(context, 'Error while trying to update Zumper syndication settings', error.message);
      } finally {
        uiFlags.isLoading = false;
      }
    }

    return {
      uiFlags,
      isZumperSyndicationEnabled,
      updateSyndicationSettings,
    };
  },
};
</script>
