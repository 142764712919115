<template>
  <div>
    <ElDialog
      :center="true"
      top="30vh"
      :show-close="false"
      :visible="uiFlags.showDeactivateAIPhoneDialog"
      :width="$viewport.lt.md ? 'auto': '500px'"
      :fullscreen="$viewport.lt.md"
    >
      <div class="text-center">
        <h3 class="font-21">
          Disable AI phone line
        </h3>
        <p class="mt-3">
          Are you sure you want to disable the AI phone line?
        </p>
        <p class="mt-3">
          The phone number will immediately be out of service, and your monthly invoice will show a prorated charge for the phone line.
        </p>
      </div>
      <div
        slot="footer"
        class="row justify-content-center"
      >
        <div class="col-auto">
          <ElButton
            class="w-100"
            size="medium"
            @click="cancelDeactivateAILeasingAgentSettings"
          >
            Cancel
          </ElButton>
        </div>
        <div class="col-auto">
          <ElButton
            class="w-100"
            size="medium"
            type="primary"
            @click="deactivateAILeasingAgentSettings"
          >
            Yes, disable
          </ElButton>
        </div>
      </div>
    </ElDialog>
    <div class="row">
      <div class="col-12 col-md-10">
        <div class="mb-3">
          <h3 class="font-21">
            AI Leasing Assistant
          </h3>
          <p class="font-15">
            Answer questions from prospective renters without lifting a finger. A web chatbot is included with the Showdigs listing widget,
            and a phone line can also be purchased to respond to calls and texts.
          </p>
          <h3 class="font-21 mt-4">
            Training Materials
          </h3>
          <p>
            The best way to train your AI is to provide a list of questions and responses, which will prepare it for questions to expect with the relevant information for that question. The AI will not reply with this text verbatim, but will use the information in the responses to formulate an answer. The more information the AI has, the more helpful its responses.
          </p>
          <div
            v-if="!actions.isUpdatingServerWithDefaultTrainingMaterialsValues"
            v-loading="actions.isUpdatingServerWithDefaultTrainingMaterialsValues"
            class="row no-gutters mt-4"
          >
            <div class="col-12">
              <ElForm
                ref="trainingMaterialForms"
                :model="arrayToObject(trainingMaterialsRef)"
              >
                <div
                  v-for="(item, index) in trainingMaterialsRef"
                  :key="index"
                >
                  <div class="d-flex flex-wrap">
                    <div class="flex-grow-1">
                      <SdFloatLabel label="Question">
                        <ElInput
                          v-model="item.question"
                          rows="5"
                          type="text"
                          placeholder="Question"
                        />
                      </SdFloatLabel>
                      <SdFloatLabel
                        label="Response"
                        class="mt-3"
                      >
                        <ElInput
                          v-model="item.response"
                          rows="5"
                          type="textarea"
                          placeholder="Response"
                        />
                      </SdFloatLabel>
                    </div>
                    <div class="d-flex flex-column ml-3 mt-1">
                      <ElButton
                        class="p-1 text-danger border-danger font-18 delete-question-btn"
                        icon="sdicon-trash"
                        :loading="currentTrainingMaterialsDeletedQuestion === index"
                        @click="deleteTrainingMaterialsQuestion(index)"
                      />
                    </div>
                  </div>
                  <ElDivider
                    v-if="index < (trainingMaterialsRef.length - 1)"
                    class="my-5"
                  />
                </div>
              </ElForm>
              <ElButton
                size="small"
                class="mt-4"
                @click="addTrainingMaterialsQuestion"
              >
                <i class="font-12 sdicon-plus" />
                {{ trainingMaterialsRef.length ? 'Add another one' : 'Add new training material' }}
              </ElButton>
              <div class="mt-4 d-flex justify-content-end">
                <ElButton
                  class="font-18"
                  type="primary"
                  size="small"
                  @click="updateTrainingMaterialsSettings()"
                >
                  Update
                </ElButton>
              </div>
            </div>
          </div>
          <div class="row no-gutters">
            <ElDivider class="mt-4 mb-0" />
          </div>
          <h3 class="font-21 mt-5">
            Web Chat
          </h3>
          <p>
            Add your AI assistant to your website. Select where you’d like the bot to appear, and then install our listing widget on your site to
            start responding to prospects today!
          </p>
          <SdFloatLabel>
            <ElSelect
              v-model="webChatOptionRef"
              placeholder="Web chatbot"
              @input="updateWebChatSettings"
            >
              <ElOption
                v-for="({label, value}) in WebsiteChatOptions"
                :key="value"
                :label="label"
                :value="value"
              />
            </ElSelect>
          </SdFloatLabel>
        </div>
        <div class="row no-gutters">
          <ElDivider class="mt-4 mb-0" />
          <div
            v-loading="actions.isDeactivatingAILeasingAgent || actions.isActivatingAILeasingAgent"
            class="ai-leasing-assistant-container mt-5"
          >
            <div class="d-flex justify-content-between align-items-baseline">
              <h3 class="font-21 position-relative">
                <i class="sdicon-phone-ai font-21" />
                <span>AI Phone Line</span>
              </h3>
              <ElSwitch
                v-model="isAILeasingAgentEnabledRef"
                :width="44"
                class="d-block align-self-center"
                @change="toggleAIPhoneLineSettings"
              />
            </div>
            <p class="font-15 mt-2">
              Want to offload responding to calls and texts too? Get a single phone number for your entire business, and let our AI assistant answer
              calls, and convert your leads.
            </p>
            <div class="mt-2 font-18 text-center">
              <div
                v-if="isAILeasingAgentEnabledRef && phoneNumberFormatted !== ''"
              >
                <span class="font-weight-bold">
                  Your Number:
                </span>
                <span class="ml-1">{{ phoneNumberFormatted }}</span>
                <SdCopyToClipboard
                  class="ml-2"
                  :copy="copiedPhoneNumber"
                  icon="sdicon-copy"
                  text="Copy"
                  text-copied="Copied"
                  input-id="copy-phone-number"
                  text-css-class="copy-phone-number"
                />
              </div>
              <h3
                v-else
                class="font-21 text-center font-weight-bold mt-2"
              >
                <span class="font-32">$200</span> per month
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  WebsiteChatOptions,
  DefaultTrainingMaterials,
} from '@/constants/Business';

import { reactive, ref } from '@vue/composition-api';
import SdFloatLabel from '@/components/common/form/SdFloatLabel.vue';
import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';
import get from 'lodash.get';
import isEqual from 'lodash.isequal';
import SdCopyToClipboard from '@/components/common/SdCopyToClipboard';

export default {
  name: 'BusinessSettingsAILeasingAssistantTab',
  components: { SdCopyToClipboard, SdFloatLabel },
  props: {
    payload: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, context) {
    const store = context.root.$store;

    const FIELDS = {
      WEBSITE_CHAT: 'website_chat',
      TRAINING_MATERIALS: 'training_materials',
      AI_LEASING_AGENT_ENABLED: 'ai_leasing_agent_enabled',
    };

    const webChatOptionRef = ref(props.payload.website_chat);

    const currentTrainingMaterialsDeletedQuestion = ref(null);

    const uiFlags = reactive({
      showDeactivateAIPhoneDialog: false,
    });

    const actions = reactive({
      isUpdatingServerWithDefaultTrainingMaterialsValues: false,
      isDeactivatingAILeasingAgent: false,
      isActivatingAILeasingAgent: false,
    });

    const isAILeasingAgentEnabledRef = ref(Boolean(props.payload.ai_leasing_agent_enabled_at));
    const copiedPhoneNumber = ref(props.payload.ai_leasing_agent_phone_number?.phoneNumber ?? '');
    const phoneNumberFormatted = ref(props.payload.ai_leasing_agent_phone_number?.formattedPhoneNumber ?? '');
    const trainingMaterialsRef = ref([]);
    handleTrainingMaterialInitialData();

    return {
      FIELDS,
      actions,
      uiFlags,
      WebsiteChatOptions,
      webChatOptionRef,
      updateWebChatSettings,
      trainingMaterialsRef,
      hasChanges,
      isAILeasingAgentEnabledRef,
      phoneNumberFormatted,
      copiedPhoneNumber,
      toggleAIPhoneLineSettings,
      deactivateAILeasingAgentSettings,
      cancelDeactivateAILeasingAgentSettings,
      updateTrainingMaterialsSettings,
      addTrainingMaterialsQuestion,
      deleteTrainingMaterialsQuestion,
      currentTrainingMaterialsDeletedQuestion,
      arrayToObject,
    };

    async function handleTrainingMaterialInitialData() {
      // Use existing data form the server.
      if (props.payload.training_materials) {
        trainingMaterialsRef.value = props.payload.training_materials;
      // Use the default data and update (sync) server with the default data as well.
      } else {
        actions.isUpdatingServerWithDefaultTrainingMaterialsValues = true;

        const business = await updateBusinessSettings(
          FIELDS.TRAINING_MATERIALS,
          DefaultTrainingMaterials,
        );

        if (business) {
          trainingMaterialsRef.value = DefaultTrainingMaterials;
        }

        actions.isUpdatingServerWithDefaultTrainingMaterialsValues = false;
      }
    }

    function addTrainingMaterialsQuestion() {
      const newEmptyQuestion = {
        question: '',
        response: '',
      };

      trainingMaterialsRef.value.push(newEmptyQuestion);
    }

    function deleteTrainingMaterialsQuestion(index) {
      currentTrainingMaterialsDeletedQuestion.value = index;

      setTimeout(() => {
        trainingMaterialsRef.value.splice(index, 1);
        currentTrainingMaterialsDeletedQuestion.value = null;
      }, 300);
    }
    async function updateTrainingMaterialsSettings() {
      await updateBusinessSettings(
        FIELDS.TRAINING_MATERIALS,
        trainingMaterialsRef.value,
        'AI Training Materials settings where updated.',
        3000,
      );
    }

    function arrayToObject(array) {
      return array.reduce((obj, item, index) => {
        obj[index] = item;
        return obj;
      }, {});
    }

    function hasChanges(originalPayload) {
      const originalValue = get(originalPayload, FIELDS.TRAINING_MATERIALS);
      const currentValue = get(props.payload, FIELDS.TRAINING_MATERIALS);
      return isEqual(originalValue, currentValue) === false;
    }

    function boldText(text) {
      return `<span class="font-weight-strong">${text}</span>`;
    }

    function cancelDeactivateAILeasingAgentSettings() {
      actions.isDeactivatingAILeasingAgent = false;
      uiFlags.showDeactivateAIPhoneDialog = false;
    }

    async function updateWebChatSettings() {
      const newValue = webChatOptionRef.value;
      const option = WebsiteChatOptions.find((option) => option.value === newValue)?.label;
      const successMessage = `Web Chat settings has been set to ${boldText(option)}.`;
      await updateBusinessSettings(FIELDS.WEBSITE_CHAT, newValue, successMessage);
    }

    async function toggleAIPhoneLineSettings(value) {
      const isToggledOff = !value;

      if (isToggledOff) {
        // Deactivating depends on the chosen user action of the presented dialog.
        uiFlags.showDeactivateAIPhoneDialog = true;
        // We preserve the value of "on" (although the user toggled it to "off"), until we verify
        // that the user selected the follow-up action of "disable" from the dialog.
        isAILeasingAgentEnabledRef.value = true;
        return;
      }

      // In case its toggle On.
      isAILeasingAgentEnabledRef.value = false;
      actions.isActivatingAILeasingAgent = true;
      const successMessage = `AI Phone line has been ${boldText('Enabled')}.`;

      const business = await updateBusinessSettings(
        FIELDS.AI_LEASING_AGENT_ENABLED,
        true,
        successMessage,
      );

      if (business) {
        isAILeasingAgentEnabledRef.value = true;
        copiedPhoneNumber.value = business.ai_leasing_agent_phone_number?.phoneNumber ?? '';
        phoneNumberFormatted.value = business.ai_leasing_agent_phone_number?.formattedPhoneNumber ?? '';
      }

      actions.isActivatingAILeasingAgent = false;
    }

    async function deactivateAILeasingAgentSettings() {
      actions.isDeactivatingAILeasingAgent = true;
      const successMessage = `AI Phone line has been ${boldText('Deactivated')}.`;
      const business = await updateBusinessSettings(
        FIELDS.AI_LEASING_AGENT_ENABLED,
        false,
        successMessage,
      );

      actions.isDeactivatingAILeasingAgent = false;
      uiFlags.showDeactivateAIPhoneDialog = false;

      if (business) {
        isAILeasingAgentEnabledRef.value = false;
      }

      copiedPhoneNumber.value = '';
      phoneNumberFormatted.value = '';
    }

    async function updateBusinessSettings(key, value, message = null, duration = 5000) {
      try {
        const response = await store.dispatch('BusinessSettings/updateAndCommitPayload', {
          meta: key,
          payload: {
            [key]: value,
          },
        });
        if (message) {
          showSuccessNotify(context, message, { duration, dangerouslyUseHTMLString: true });
        }
        return response;
      } catch (error) {
        showErrorNotify(context, error.message);
        return null;
      }
    }
  },
};

</script>
<style lang="scss" scoped>
  .ai-leasing-assistant-container {
    h3 {
      padding-left: 35px;

    .sdicon-phone-ai {
      position: absolute;
      top: -2px;
      left: 0;
      }
    }
  }

  .delete-question-btn:hover{
    background-color: $white;
  }

  ::v-deep .copy-wrapper {
    font-size: 13px;
    color: gray-color('dark');

  .copy-phone-number {
    display: inline-block;
  }

  .sdicon-copy {
    font-size: inherit;
    color: inherit;
  }
}
</style>
